import {
    IconSocialMediaFacebook,
    IconSocialMediaInstagram,
    IconSocialMediaTwitter,
    Logo,
    ExternalLink,
    NamedLink,
} from '../../components';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './Footer.module.css';
import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

const renderSocialMediaLinks = (intl) => {
    const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
    const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
    const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

    const fbLink = siteFacebookPage ? (
        <ExternalLink
            key="linkToFacebook"
            href={siteFacebookPage}
            className={css.icon}
            title={goToFb}>
            <IconSocialMediaFacebook />
        </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
        <ExternalLink
            key="linkToTwitter"
            href={siteTwitterPage}
            className={css.icon}
            title={goToTwitter}>
            <IconSocialMediaTwitter />
        </ExternalLink>
    ) : null;

    const instragramLink = siteInstagramPage ? (
        <ExternalLink
            key="linkToInstagram"
            href={siteInstagramPage}
            className={css.icon}
            title={goToInsta}>
            <IconSocialMediaInstagram />
        </ExternalLink>
    ) : null;
    return [fbLink, twitterLink, instragramLink].filter((v) => v != null);
};

const Footer = (props) => {
    const { rootClassName, className, intl } = props;
    const socialMediaLinks = renderSocialMediaLinks(intl);
    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.topBorderWrapper}>
                <div className={css.content}>
                    <div className={css.someLiksMobile}>{socialMediaLinks}</div>
                    <div className={css.links}>
                        <div className={css.organization} id="organization">
                            <NamedLink
                                name="LandingPage"
                                className={css.logoLink}>
                                <Logo format="desktop" className={css.logo} />
                            </NamedLink>
                            <div className={css.organizationInfo}>
                                <p className={css.organizationDescription}>
                                    <FormattedMessage id="Footer.organizationDescription" />
                                </p>
                                <p className={css.organizationCopyright}>
                                    <NamedLink
                                        name="LandingPage"
                                        className={css.copyrightLink}>
                                        <FormattedMessage id="Footer.copyright" />
                                    </NamedLink>
                                </p>
                            </div>
                        </div>
                        <div className={css.infoLinks}>
                            <ul className={css.list}>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="NewListingPage"
                                        className={css.link}>
                                        <FormattedMessage id="Footer.toNewListingPage" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <ExternalLink
                                        name="About us"
                                        href="https://www.creator-cosmos.com/"
                                        className={css.link}>
                                        
                                        <FormattedMessage id="Footer.toAboutPage" />
                                    </ExternalLink>
                                    
                                </li>
                                <li className={css.listItem}>
                                    <ExternalLink
                                        name="FAQ"
                                        href="https://www.creator-cosmos.com/"
                                        className={css.link}>
                                        
                                        <FormattedMessage id="Footer.toFAQPage" />
                                    </ExternalLink>
                                </li>
                                <li className={css.listItem}>
                                    <ExternalLink
                                        name="FAQ"
                                        href="mailto:dominik@creator-cosmos.com"
                                        className={css.link}>
                                        
                                        <FormattedMessage id="Footer.toHelpPage" />
                                    </ExternalLink>
                                </li>
                                <li className={css.listItem}>
                                    <ExternalLink
                                        name="FAQ"
                                        href="mailto:dominik@creator-cosmos.com"
                                        className={css.link}>
                                        
                                        <FormattedMessage id="Footer.toContactPage" />
                                    </ExternalLink>
                                </li>
                            </ul>
                        </div>
                        <div className={css.searches}>
                            <ul className={css.list}>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=fashion',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link1" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=health_and_fitness',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link2" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=Entrepreneur_and_business',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link3" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=beauty',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link4" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=gaming',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link5" />
                                    </NamedLink>
                                </li>
                            </ul>
                        </div>
                        <div className={css.searchesExtra}>
                            <ul className={css.list}>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=education',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link6" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=automotive',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link7" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=music_and_dance',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link8" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=food_and_drink',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link9" />
                                    </NamedLink>
                                </li>
                                <li className={css.listItem}>
                                    <NamedLink
                                        name="SearchPage"
                                        to={{
                                            search: '?pub_category=diy',
                                        }}
                                        className={css.link}>
                                        <FormattedMessage id="Footer.link10" />
                                    </NamedLink>
                                </li>
                            </ul>
                        </div>
                        <div className={css.extraLinks}>
                            <div className={css.someLinks}>
                                {socialMediaLinks}
                            </div>
                            <div className={css.legalMatters}>
                                <ul className={css.tosAndPrivacy}>
                                    <li>
                                        <NamedLink
                                            name="TermsOfServicePage"
                                            className={css.legalLink}>
                                            <FormattedMessage id="Footer.termsOfUse" />
                                        </NamedLink>
                                    </li>
                                    <li>
                                        <NamedLink
                                            name="PrivacyPolicyPage"
                                            className={css.legalLink}>
                                            <FormattedMessage id="Footer.privacyPolicy" />
                                        </NamedLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={css.copyrightAndTermsMobile}>
                        <NamedLink
                            name="LandingPage"
                            className={css.organizationCopyrightMobile}>
                            <FormattedMessage id="Footer.copyright" />
                        </NamedLink>
                        <div className={css.tosAndPrivacyMobile}>
                            <NamedLink
                                name="PrivacyPolicyPage"
                                className={css.privacy}>
                                <FormattedMessage id="Footer.privacy" />
                            </NamedLink>
                            <NamedLink
                                name="TermsOfServicePage"
                                className={css.terms}>
                                <FormattedMessage id="Footer.terms" />
                            </NamedLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.defaultProps = {
    rootClassName: null,
    className: null,
};

Footer.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
};

export default injectIntl(Footer);
