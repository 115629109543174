/**
 * This component wraps React-Router's Redirect by providing name-based routing.
 * (Helps to narrow down the scope of possible format changes to routes.)
 */
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

const NamedRedirect = (props) => {
    const { name, search, state, params, push } = props;
    const pathname = pathByRouteName(name, routeConfiguration(), params);
    return <Redirect to={{ pathname, search, state }} push={push} />;
};

const { bool, object, string } = PropTypes;

NamedRedirect.defaultProps = { search: '', state: {}, push: false, params: {} };

NamedRedirect.propTypes = {
    name: string.isRequired,
    search: string,
    state: object,
    push: bool,
    params: object,
};

export default NamedRedirect;
