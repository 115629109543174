import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import unionWith from 'lodash/unionWith';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST =
    'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS =
    'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR =
    'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING =
    'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
    pagination: null,
    searchParams: null,
    searchInProgress: false,
    searchListingsError: null,
    currentPageResultIds: [],
    searchMapListingIds: [],
    searchMapListingsError: null,
};

const resultIds = (data) => data.data.map((l) => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_LISTINGS_REQUEST:
            return {
                ...state,
                searchParams: payload.searchParams,
                searchInProgress: true,
                searchMapListingIds: [],
                searchListingsError: null,
            };
        case SEARCH_LISTINGS_SUCCESS:
            return {
                ...state,
                currentPageResultIds: resultIds(payload.data),
                pagination: payload.data.meta,
                searchInProgress: false,
            };
        case SEARCH_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                searchInProgress: false,
                searchListingsError: payload,
            };

        case SEARCH_MAP_LISTINGS_REQUEST:
            return {
                ...state,
                searchMapListingsError: null,
            };
        case SEARCH_MAP_LISTINGS_SUCCESS: {
            const searchMapListingIds = unionWith(
                state.searchMapListingIds,
                resultIds(payload.data),
                (id1, id2) => id1.uuid === id2.uuid,
            );
            return {
                ...state,
                searchMapListingIds,
            };
        }
        case SEARCH_MAP_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, searchMapListingsError: payload };

        case SEARCH_MAP_SET_ACTIVE_LISTING:
            return {
                ...state,
                activeListingId: payload,
            };
        default:
            return state;
    }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams) => ({
    type: SEARCH_LISTINGS_REQUEST,
    payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
    type: SEARCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchListingsError = (e) => ({
    type: SEARCH_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const searchMapListingsRequest = () => ({
    type: SEARCH_MAP_LISTINGS_REQUEST,
});

export const searchMapListingsSuccess = (response) => ({
    type: SEARCH_MAP_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const searchMapListingsError = (e) => ({
    type: SEARCH_MAP_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const searchListings = (searchParams) => (dispatch, getState, sdk) => {
    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = (priceParam) => {
        const inSubunits = (value) =>
            convertUnitToSubUnit(
                value,
                unitDivisor(config.currencyConfig.currency),
            );
        const values = priceParam ? priceParam.split(',') : [];
        return priceParam && values.length === 2
            ? {
                  price: [
                      inSubunits(values[0]),
                      inSubunits(values[1]) + 1,
                  ].join(','),
              }
            : {};
    };

    const datesSearchParams = (datesParam) => {
        const values = datesParam ? datesParam.split(',') : [];
        const hasValues = datesParam && values.length === 2;
        const startDate = hasValues ? values[0] : null;
        const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
        const endDate =
            hasValues && isNightlyBooking
                ? values[1]
                : hasValues
                ? getExclusiveEndDate(values[1], 'Etc/UTC')
                : null;

        return hasValues
            ? {
                  start: parseDateFromISO8601(startDate, 'Etc/UTC'),
                  end: parseDateFromISO8601(endDate, 'Etc/UTC'),
                  // Availability can be full or partial. Default value is full.
                  availability: 'full',
              }
            : {};
    };

    const { perPage, price, dates, sort, ...rest } = searchParams;
    const priceMaybe = priceSearchParams(price);
    const datesMaybe = datesSearchParams(dates);
    const sortMaybe =
        sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

    const params = {
        ...rest,
        ...priceMaybe,
        ...datesMaybe,
        ...sortMaybe,
        per_page: perPage,
    };

    return sdk.listings
        .query(params)
        .then((response) => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchListingsSuccess(response));
            return response;
        })
        .catch((e) => {
            dispatch(searchListingsError(storableError(e)));
            throw e;
        });
};

export const setActiveListing = (listingId) => ({
    type: SEARCH_MAP_SET_ACTIVE_LISTING,
    payload: listingId,
});

export const searchMapListings =
    (searchParams) => (dispatch, getState, sdk) => {
        dispatch(searchMapListingsRequest(searchParams));

        const { perPage, ...rest } = searchParams;
        const params = {
            ...rest,
            per_page: perPage,
        };

        return sdk.listings
            .query(params)
            .then((response) => {
                dispatch(addMarketplaceEntities(response));
                dispatch(searchMapListingsSuccess(response));
                return response;
            })
            .catch((e) => {
                dispatch(searchMapListingsError(storableError(e)));
                throw e;
            });
    };

export const loadData = (params, search) => {
    const queryParams = parse(search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });

    // Add minStock filter with default value (1), if stock management is in use.
    // This can be overwriten with passed-in query parameters.
    const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
    const { page = 1, address, origin, ...rest } = queryParams;
    const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

    const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
    } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;

    return searchListings({
        // ...minStockMaybe,
        ...rest,
        // ...originMaybe,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
    });
};
