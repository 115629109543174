import {
    Form,
    IconSpinner,
    PrimaryButton,
    FieldDateRangeInput,
} from '../../../components';
import config from '../../../config';
import { START_DATE, END_DATE, getStartOf, addTime } from '../../../util/dates';
import {
    FormattedMessage,
    intlShape,
    injectIntl,
} from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {
    required,
    bookingDatesRequired,
    composeValidators,
} from '../../../util/validators';
import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';
import css from './BookingDatesForm.module.css';
import classNames from 'classnames';
import { string, bool, arrayOf, array, func } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';

const identity = (v) => v;

export class BookingDatesFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { focusedInput: null };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    // Function that can be passed to nested components
    // so that they can notify this component when the
    // focused input changes.
    onFocusedInputChange(focusedInput) {
        this.setState({ focusedInput });
    }

    // In case start or end date for the booking is missing
    // focus on that input, otherwise continue with the
    // default handleSubmit function.
    handleFormSubmit(e) {
        const { startDate, endDate } = e.bookingDates || {};
        if (!startDate) {
            e.preventDefault();
            this.setState({ focusedInput: START_DATE });
        } else if (!endDate) {
            e.preventDefault();
            this.setState({ focusedInput: END_DATE });
        } else {
            this.props.onSubmit(e);
        }
    }

    // When the values of the form are updated we need to fetch
    // lineItems from FTW backend for the EstimatedTransactionMaybe
    // In case you add more fields to the form, make sure you add
    // the values here to the orderData object.
    handleOnChange(formValues) {
        const { startDate, endDate } =
            formValues.values && formValues.values.bookingDates
                ? formValues.values.bookingDates
                : {};
        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;

        if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
            this.props.onFetchTransactionLineItems({
                orderData: { bookingDates: { startDate, endDate } },
                listingId,
                isOwnListing,
            });
        }
    }

    render() {
        const {
            rootClassName,
            className,
            price: unitPrice,
            ...rest
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);

        if (!unitPrice) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
                    </p>
                </div>
            );
        }
        if (unitPrice.currency !== config.currency) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
                    </p>
                </div>
            );
        }

        return (
            <FinalForm
                {...rest}
                unitPrice={unitPrice}
                onSubmit={this.handleFormSubmit}
                render={(fieldRenderProps) => {
                    const {
                        endDatePlaceholder,
                        startDatePlaceholder,
                        formId,
                        handleSubmit,
                        intl,
                        isOwnListing,
                        submitButtonWrapperClassName,
                        unitType,
                        values,
                        timeSlots,
                        fetchTimeSlotsError,
                        lineItems,
                        fetchLineItemsInProgress,
                        fetchLineItemsError,
                    } = fieldRenderProps;
                    const { startDate, endDate } =
                        values && values.bookingDates
                            ? values.bookingDates
                            : {};

                    const bookingStartLabel = intl.formatMessage({
                        id: 'BookingDatesForm.bookingStartTitle',
                    });
                    const bookingEndLabel = intl.formatMessage({
                        id: 'BookingDatesForm.bookingEndTitle',
                    });
                    const requiredMessage = intl.formatMessage({
                        id: 'BookingDatesForm.requiredDate',
                    });
                    const startDateErrorMessage = intl.formatMessage({
                        id: 'FieldDateRangeInput.invalidStartDate',
                    });
                    const endDateErrorMessage = intl.formatMessage({
                        id: 'FieldDateRangeInput.invalidEndDate',
                    });
                    const timeSlotsError = fetchTimeSlotsError ? (
                        <p className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.timeSlotsError" />
                        </p>
                    ) : null;

                    // This is the place to collect breakdown estimation data.
                    // Note: lineItems are calculated and fetched from FTW backend
                    // so we need to pass only booking data that is needed otherwise
                    // If you have added new fields to the form that will affect to pricing,
                    // you need to add the values to handleOnChange function
                    const breakdownData =
                        startDate && endDate
                            ? {
                                  startDate,
                                  endDate,
                              }
                            : null;

                    const showEstimatedBreakdown =
                        breakdownData &&
                        lineItems &&
                        !fetchLineItemsInProgress &&
                        !fetchLineItemsError;

                    const bookingInfoMaybe = showEstimatedBreakdown ? (
                        <div className={css.priceBreakdownContainer}>
                            <h3 className={css.priceBreakdownTitle}>
                                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
                            </h3>
                            <EstimatedCustomerBreakdownMaybe
                                unitType={unitType}
                                breakdownData={breakdownData}
                                lineItems={lineItems}
                            />
                        </div>
                    ) : null;

                    const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
                        <IconSpinner className={css.spinner} />
                    ) : null;

                    const bookingInfoErrorMaybe = fetchLineItemsError ? (
                        <span className={css.sideBarError}>
                            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
                        </span>
                    ) : null;

                    const dateFormatOptions = {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                    };

                    const now = new Date();
                    const today = getStartOf(now);
                    const tomorrow = addTime(today, 1, 'days');
                    const startDatePlaceholderText =
                        startDatePlaceholder ||
                        intl.formatDate(today, dateFormatOptions);
                    const endDatePlaceholderText =
                        endDatePlaceholder ||
                        intl.formatDate(tomorrow, dateFormatOptions);
                    const submitButtonClasses = classNames(
                        submitButtonWrapperClassName || css.submitButtonWrapper,
                    );

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            className={classes}
                            enforcePagePreloadFor="CheckoutPage">
                            {timeSlotsError}
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={(values) => {
                                    this.handleOnChange(values);
                                }}
                            />
                            <FieldDateRangeInput
                                className={css.bookingDates}
                                name="bookingDates"
                                unitType={unitType}
                                startDateId={`${formId}.bookingStartDate`}
                                startDateLabel={bookingStartLabel}
                                startDatePlaceholderText={
                                    startDatePlaceholderText
                                }
                                endDateId={`${formId}.bookingEndDate`}
                                endDateLabel={bookingEndLabel}
                                endDatePlaceholderText={endDatePlaceholderText}
                                focusedInput={this.state.focusedInput}
                                onFocusedInputChange={this.onFocusedInputChange}
                                format={identity}
                                timeSlots={timeSlots}
                                useMobileMargins
                                validate={composeValidators(
                                    required(requiredMessage),
                                    bookingDatesRequired(
                                        startDateErrorMessage,
                                        endDateErrorMessage,
                                    ),
                                )}
                                disabled={fetchLineItemsInProgress}
                            />

                            {bookingInfoMaybe}
                            {loadingSpinnerMaybe}
                            {bookingInfoErrorMaybe}

                            <p className={css.smallPrint}>
                                <FormattedMessage
                                    id={
                                        isOwnListing
                                            ? 'BookingDatesForm.ownListing'
                                            : 'BookingDatesForm.youWontBeChargedInfo'
                                    }
                                />
                            </p>
                            <div className={submitButtonClasses}>
                                <PrimaryButton type="submit">
                                    <FormattedMessage id="BookingDatesForm.requestToBook" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    }
}

BookingDatesFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    submitButtonWrapperClassName: null,
    price: null,
    isOwnListing: false,
    startDatePlaceholder: null,
    endDatePlaceholder: null,
    timeSlots: null,
    lineItems: null,
    fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    submitButtonWrapperClassName: string,

    unitType: propTypes.lineItemUnitType.isRequired,
    price: propTypes.money,
    isOwnListing: bool,
    timeSlots: arrayOf(propTypes.timeSlot),

    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from injectIntl
    intl: intlShape.isRequired,

    // for tests
    startDatePlaceholder: string,
    endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
