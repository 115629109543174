/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
    propTypes,
    LINE_ITEM_CUSTOMER_COMMISSION,
    LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import css from './OrderBreakdown.module.css';
import classNames from 'classnames';
import { oneOf, string } from 'prop-types';
import React from 'react';

export const OrderBreakdownComponent = (props) => {
    const {
        rootClassName,
        className,
        userRole,
        unitType,
        transaction,
        booking,
        intl,
        dateType,
    } = props;

    const isCustomer = userRole === 'customer';
    const isProvider = userRole === 'provider';
    const lineItems = transaction.attributes.lineItems;

    const hasCommissionLineItem = lineItems.find((item) => {
        const hasCustomerCommission =
            isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
        const hasProviderCommission =
            isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
        return (
            (hasCustomerCommission || hasProviderCommission) && !item.reversal
        );
    });

    const classes = classNames(rootClassName || css.root, className);

    /**
     * OrderBreakdown contains different line items:
     *
     * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
     * determines if the date and time or only the date is shown
     *
     * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
     * quantity of the unit (Not used with FTW-product).
     *
     * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
     * additional items into it).
     *
     * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
     *
     * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
     * "$150.00 * 2 nights $300"
     *
     * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
     * This line item is not used by default in the OrderBreakdown.
     *
     * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
     * should not be unknown line items. If you are using custom pricing, you should create
     * new custom line items if you need them.
     *
     * LineItemSubTotalMaybe: prints subtotal of line items before possible
     * commission or refunds
     *
     * LineItemRefundMaybe: prints the amount of refund
     *
     * LineItemCustomerCommissionMaybe: prints the amount of customer commission
     * The default transaction process used by FTW doesn't include the customer commission.
     *
     * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
     *
     * LineItemProviderCommissionMaybe: prints the amount of provider commission
     *
     * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
     *
     * LineItemTotalPrice: prints total price of the transaction
     *
     */

    return (
        <div className={classes}>
            <LineItemBookingPeriod
                booking={booking}
                unitType={unitType}
                dateType={dateType}
            />

            <LineItemBasePriceMaybe
                lineItems={lineItems}
                unitType={unitType}
                intl={intl}
            />
            <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
            <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
            <LineItemUnknownItemsMaybe
                lineItems={lineItems}
                isProvider={isProvider}
                intl={intl}
            />

            <LineItemSubTotalMaybe
                lineItems={lineItems}
                unitType={unitType}
                userRole={userRole}
                intl={intl}
            />
            <LineItemRefundMaybe lineItems={lineItems} intl={intl} />

            <LineItemCustomerCommissionMaybe
                lineItems={lineItems}
                isCustomer={isCustomer}
                intl={intl}
            />
            <LineItemCustomerCommissionRefundMaybe
                lineItems={lineItems}
                isCustomer={isCustomer}
                intl={intl}
            />

            <LineItemProviderCommissionMaybe
                lineItems={lineItems}
                isProvider={isProvider}
                intl={intl}
            />
            <LineItemProviderCommissionRefundMaybe
                lineItems={lineItems}
                isProvider={isProvider}
                intl={intl}
            />

            <LineItemTotalPrice
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
            />

            {hasCommissionLineItem ? (
                <span className={css.feeInfo}>
                    <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
                </span>
            ) : null}
        </div>
    );
};

OrderBreakdownComponent.defaultProps = {
    rootClassName: null,
    className: null,
    booking: null,
    dateType: null,
};

OrderBreakdownComponent.propTypes = {
    rootClassName: string,
    className: string,

    userRole: oneOf(['customer', 'provider']).isRequired,
    unitType: propTypes.lineItemUnitType.isRequired,
    transaction: propTypes.transaction.isRequired,
    booking: propTypes.booking,
    dateType: propTypes.dateType,

    // from injectIntl
    intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
