import {
    AspectRatioWrapper,
    NamedLink,
    ResponsiveImage,
} from '../../components';
import config from '../../config';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './ListingCard.module.css';
import {
    faFilm,
    faImage,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';
import React, { Component } from 'react';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: intl.formatMessage(
                { id: 'ListingCard.unsupportedPrice' },
                { currency: price.currency },
            ),
            priceTitle: intl.formatMessage(
                { id: 'ListingCard.unsupportedPriceTitle' },
                { currency: price.currency },
            ),
        };
    }
    return {};
};

class ListingImage extends Component {
    render() {
        return <ResponsiveImage {...this.props} />;
    }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
    loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = (props) => {
    const {
        className,
        rootClassName,
        intl,
        listing,
        renderSizes,
        setActiveListing,
        showAuthorInfo,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const id = currentListing.id.uuid;
    const { title = '', price } = currentListing.attributes;
    const slug = createSlug(title);
    const author = ensureUser(listing.author);
    const authorName = author.attributes.profile.displayName;
    const firstImage =
        currentListing.images && currentListing.images.length > 0
            ? currentListing.images[0]
            : null;

    const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
    } = config.listing;
    const variants = firstImage
        ? Object.keys(firstImage?.attributes?.variants).filter((k) =>
              k.startsWith(variantPrefix),
          )
        : [];

    const { formattedPrice, priceTitle } = priceData(price, intl);
    const unitType = config.lineItemUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
        ? 'ListingCard.perNight'
        : isDaily
        ? 'ListingCard.perDay'
        : 'ListingCard.perUnit';

    const setActivePropsMaybe = setActiveListing
        ? {
              onMouseEnter: () => setActiveListing(currentListing.id),
              onMouseLeave: () => setActiveListing(null),
          }
        : null;

    const { type, media, location } =
        currentListing.attributes.publicData || {};
    const mediaIcon = media ? (
        media === 'picture' ? (
            <FontAwesomeIcon
                size="xs"
                style={{ marginLeft: '5px', opacity: '0.5' }}
                icon={faImage}
            />
        ) : (
            <FontAwesomeIcon
                size="xs"
                style={{ marginLeft: '5px', opacity: '0.5' }}
                icon={faFilm}
            />
        )
    ) : null;

    const locationIcon = location ? (
        <div style={{ display: 'flex', alignItems: 'center', opacity: '0.5' }}>
            <FontAwesomeIcon
                size="sm"
                style={{ marginRight: '5px' }}
                icon={faLocationDot}
            />
            {location?.address}
        </div>
    ) : null;

    return (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                {...setActivePropsMaybe}>
                <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                />
            </AspectRatioWrapper>
            <div className={css.info}>
                <div className={css.price}>
                    <div className={css.priceValue} title={priceTitle}>
                        {formattedPrice}
                    </div>
                    {config.listing.showUnitTypeTranslations ? (
                        <div className={css.perUnit}>
                            <FormattedMessage id={unitTranslationKey} />
                        </div>
                    ) : null}
                </div>
                <div className={css.mainInfo}>
                    <div className={css.title}>
                        {richText(title, {
                            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                            longWordClass: css.longWord,
                        })}
                        {type ? (
                            <>
                                <span className={css.dotSeperator}>•</span>
                                <span className={css.type}>{type}</span>
                                {mediaIcon}
                            </>
                        ) : null}
                    </div>
                    {showAuthorInfo ? (
                        <div className={css.authorInfo}>
                            <FormattedMessage
                                id="ListingCard.author"
                                values={{ authorName }}
                            />
                        </div>
                    ) : null}
                    <div className={css.authorInfo}>{locationIcon}</div>
                </div>
            </div>
        </NamedLink>
    );
};

ListingCardComponent.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: null,
    setActiveListing: null,
    showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,
    showAuthorInfo: bool,

    // Responsive image sizes hint
    renderSizes: string,

    setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
