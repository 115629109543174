import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
    {
        id: 'default-berlin',
        predictionPlace: {
            address: 'Berlin, Germany',
            bounds: new LatLngBounds(
                new LatLng(52.6755029827484, 13.761131997363),
                new LatLng(52.3382670008426, 13.0883590415111),
            ),
        },
    },
    {
        id: 'default-munich',
        predictionPlace: {
            address: 'Munich, Germany',
            bounds: new LatLngBounds(
                new LatLng(48.248216, 11.722879),
                new LatLng(48.061596, 11.360781),
            ),
        },
    },
    {
        id: 'default-wien',
        predictionPlace: {
            address: 'Vienna, Austria',
            bounds: new LatLngBounds(
                new LatLng(47.469041, 9.547297),
                new LatLng(47.459961, 9.525698),
            ),
        },
    },
    {
        id: 'default-milano',
        predictionPlace: {
            address: 'Milano, Italy',
            bounds: new LatLngBounds(
                new LatLng(45.535973, 9.277905),
                new LatLng(45.387103, 9.041082),
            ),
        },
    },

    
];
export default defaultLocations;
