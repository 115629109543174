import css from './TermsOfService.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const TermsOfService = (props) => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: June 26th, 2022</p>

      <p>
        The following notes provide a simple overview of what happens to your personal data when 
        you visit this website. Personal data is any data by which you can be personally identified.
         For detailed information on the subject of data protection, please refer to our data protection 
        declaration listed below this text.
        <br></br><br></br>
        Data collection on this website <br></br>
        Who is responsible for data collection on this website?
        <br></br>
        Data processing on this website is carried out by the website operator. You can find his contact details in the imprint of this website.
        <br></br><br></br>
        How do we collect your data? 
        <br></br>
        On the one hand, your data is collected by you providing it to us. This can be, for example, data that you enter in a contact form. Other data is collected automatically or after your consent when you visit the website by our IT systems. This is mainly technical data (e.g. Internet browser, operating system or time of page view). The collection of this data takes place automatically as soon as you enter this website.
        <br></br><br></br>
        What do we use your data for? <br></br>
        Some of the data is collected to ensure error-free provision of the website. Other data may be used to analyze your user behavior.
        <br></br><br></br>
        What rights do you have regarding your data? <br></br>
        You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have a right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority. For this purpose, as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint.
        <br></br><br></br>
        SSL or TLS encryption <br></br>
        For security reasons and to protect the transmission of confidential content, such as orders or requests that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line. <br></br>
        If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
        <br></br><br></br>
​        Information, deletion and correction<br></br>
        Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of data processing and, if necessary, a right to correction or deletion of this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time at the address given in the imprint. <br></br>
        <br></br><br></br>
        Right to restriction of processing<br></br>
        You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time at the address given in the imprint. The right

        to restriction of processing exists in the following cases:

        If you dispute the accuracy of your personal data stored by us, we usually need time to verify this. For the duration of the review, you have the right to request the restriction of the processing of your personal data. If the processing of your personal data happened/is happening unlawfully, you can request the restriction of data processing instead of deletion. If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request restriction of the processing of your personal data instead of erasure.
        <br></br><br></br>

        If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a balancing of your and our interests must be carried out. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data. If you have restricted the processing of your personal data, this data - apart from being stored - may only be processed with your consent or for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.
      </p>

      <h2>General Notes and Mandatory Information</h2>
      <p>
        Data protection <br></br>

        The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.
        <br></br><br></br>
        IF YOUR PERSONAL DATA IS PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING; THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS ASSOCIATED WITH SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ARTICLE 21 (2) DSGVO).
        <br></br><br></br>
        Right of appeal to the competent supervisory authority<br></br>
        In the event of violations of the GDPR, data subjects shall have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, their place of work or the place of the alleged violation. The right of appeal is without prejudice to any other administrative or judicial remedy. Right to data portability You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.
      


      </p>

      <h2>Data collection on this website</h2>
      <p>
      Cookies<br></br>

      Our Internet pages use so-called "cookies". Cookies are small text files and do not cause any damage to your terminal device. They are stored either temporarily for the duration of a session (session cookies) or permanently (permanent cookies) on your terminal device. Session cookies are automatically deleted at the end of your visit. Permanent cookies remain stored on your end device until you delete them yourself or until they are automatically deleted by your web browser. In some cases, cookies from third-party companies may also be stored on your terminal device when you enter our site (third-party cookies). These enable us or you to use certain services of the third-party company (e.g. cookies for processing payment services). Cookies have various functions. Many cookies are technically necessary, as certain website functions would not work without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or display advertising. Cookies that are necessary to carry out the electronic communication process (necessary cookies) or to provide certain functions that you have requested (functional cookies, e.g. for the shopping cart function) or to optimize the website (e.g. cookies to measure the web audience) are stored on the basis of Art. 6 (1) lit. f DSGVO, unless another legal basis is specified. The website operator has a legitimate interest in storing cookies for the technically error-free and optimized provision of its services. Insofar as a

      If you have been asked for your consent to the storage of cookies, the storage of the cookies in question is based exclusively on this consent (Art. 6 para. 1 lit. a DSGVO); the consent can be revoked at any time. You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website may be limited. If cookies are used by third-party companies or for analysis purposes, we will inform you about this separately within the framework of this data protection declaration and, if necessary, request your consent.
      <br></br><br></br>

      Contact form<br></br>
    
      If you send us inquiries via the contact form, your data from the inquiry form, including the contact data you provided there, will be stored by us for the purpose of processing the inquiry and in case of follow-up questions. We do not pass on this data without your consent.

 

      The processing of this data is based on Art. 6 (1) lit. b DSGVO, if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6 (1) (f) DSGVO) or on your consent (Art. 6 (1) (a) DSGVO) if this has been requested. The data you enter in the contact form will remain with us until you request us to delete it, revoke your consent to store it, or the purpose for storing the data no longer applies (e.g. after we have completed processing your request). Mandatory legal provisions - in particular retention periods - remain unaffected.
      <br></br><br></br>

      Inquiry by e-mail or telephone <br></br>

      If you contact us by e-mail or telephone, your inquiry including all resulting personal data (name, inquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent. The processing of this data is based on Art. 6 (1) lit. b DSGVO, if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the requests sent to us (Art. 6 (1) (f) DSGVO) or on your consent (Art. 6 (1) (a) DSGVO) if this has been requested. The data you send to us via contact requests will remain with us until you request us to delete it, revoke your consent to store it, or the purpose for storing the data no longer applies (e.g. after your request has been processed). Mandatory legal provisions - in particular legal retention periods - remain unaffected.etzen responsible. According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately.
      
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
    rootClassName: string,
    className: string,
};

export default TermsOfService;
