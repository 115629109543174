import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './Reviews.module.css';
import classNames from 'classnames';
import { arrayOf, string } from 'prop-types';
import React from 'react';

const Review = (props) => {
    const { review, intl } = props;

    const date = review.attributes.createdAt;
    const dateString = intl.formatDate(date, {
        month: 'long',
        year: 'numeric',
    });

    return (
        <div className={css.review}>
            <Avatar className={css.avatar} user={review.author} />
            <div>
                <ReviewRating
                    rating={review.attributes.rating}
                    className={css.mobileReviewRating}
                    reviewStarClassName={css.reviewRatingStar}
                />
                <p className={css.reviewContent}>{review.attributes.content}</p>
                <p className={css.reviewInfo}>
                    <UserDisplayName user={review.author} intl={intl} />
                    <span className={css.separator}>•</span>
                    {dateString}
                    <span className={css.desktopSeparator}>•</span>
                    <span className={css.desktopReviewRatingWrapper}>
                        <ReviewRating
                            rating={review.attributes.rating}
                            className={css.desktopReviewRating}
                            reviewStarClassName={css.reviewRatingStar}
                        />
                    </span>
                </p>
            </div>
        </div>
    );
};

Review.propTypes = {
    review: propTypes.review.isRequired,
    intl: intlShape.isRequired,
};

const ReviewsComponent = (props) => {
    const { className, rootClassName, reviews, intl } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (
        <ul className={classes}>
            {reviews.map((r) => {
                return (
                    <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                        <Review review={r} intl={intl} />
                    </li>
                );
            })}
        </ul>
    );
};

ReviewsComponent.defaultProps = {
    className: null,
    rootClassName: null,
    reviews: [],
};

ReviewsComponent.propTypes = {
    className: string,
    rootCalssName: string,
    reviews: arrayOf(propTypes.review),

    // from injectIntl
    intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
