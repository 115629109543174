/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
    {
        id: 'platform',
        label: 'Platform',
        type: 'SelectSingleFilter',
        group: 'primary',
        queryParamNames: ['pub_platform'],
        config: {
            // Schema type is enum for SelectSingleFilter
            schemaType: 'enum',

            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                { key: 'facebook', label: 'Facebook' },
                { key: 'instagram', label: 'Instagram' },
                { key: 'tiktok', label: 'TikTok' },
                { key: 'twitter', label: 'Twitter' },
                { key: 'youtube', label: 'Youtube' },
            ],
        },
    },
    {
        id: 'type',
        label: 'Type',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_type'],
        config: {
            // Schema type is enum for SelectSingleFilter
            schemaType: 'enum',

            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                { key: 'post', label: 'Post' },
                { key: 'story', label: 'Story' },
                { key: 'live', label: 'Live' },
            ],
        },
    },
    {
        id: 'media',
        label: 'Media',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_media'],
        config: {
            // Schema type is enum for SelectSingleFilter
            schemaType: 'enum',

            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                { key: 'picture', label: 'Picture' },
                { key: 'video', label: 'Video' },
            ],
        },
    },
    {
        id: 'category',
        label: 'Category',
        type: 'SelectMultipleFilter',
        group: 'secondary',
        queryParamNames: ['pub_category'],
        config: {
            // Schema type is enum for SelectSingleFilter
            schemaType: 'enum',

            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                { key: 'fashion', label: 'Fashion' },
                { key: 'beauty', label: 'Beauty' },
                { key: 'health_and_fitness', label: 'Health and Fitness' },
                { key: 'travel', label: 'Travel' },
                { key: 'food_and_drink', label: 'Food and Drink' },
                { key: 'art_and_photography', label: 'Art and Photography' },
                { key: 'music_and_dance', label: 'Music and Dance' },
                {
                    key: 'Entrepreneur_and_business',
                    label: 'Entrepreneur and Business',
                },
                { key: 'family_and_children', label: 'Family and Children' },
                { key: 'animal_and_pets', label: 'Animal and Pets' },
                { key: 'education', label: 'Education' },
                { key: 'gaming', label: 'Gaming' },
                { key: 'lgbtq2+', label: 'LGBTQ2+' },
                { key: 'diy', label: 'DIY' },
                { key: 'automative', label: 'Automative' },
            ],
        },
    },
    {
        id: 'price',
        label: 'Price',
        type: 'PriceFilter',
        group: 'primary',
        // Note: PriceFilter is fixed filter,
        // you can't change "queryParamNames: ['price'],"
        queryParamNames: ['price'],
        // Price filter configuration
        // Note: unlike most prices this is not handled in subunits
        config: {
            min: 0,
            max: 1000,
            step: 5,
        },
    },
    {
        id: 'keyword',
        label: 'Keyword',
        type: 'KeywordFilter',
        group: 'primary',
        // Note: KeywordFilter is fixed filter,
        // you can't change "queryParamNames: ['keywords'],"
        queryParamNames: ['keywords'],
        // NOTE: If you are ordering search results by distance
        // the keyword search can't be used at the same time.
        // You can turn on/off ordering by distance from config.js file.
        config: {},
    },

    // Here is an example of multi-enum search filter.
    //
    // {
    //   id: 'amenities',
    //   label: 'Amenities',
    //   type: 'SelectMultipleFilter',
    //   group: 'secondary',
    //   queryParamNames: ['pub_amenities'],
    //   config: {
    //     // Schema type options: 'enum', 'multi-enum'
    //     // Both types can work so that user selects multiple values when filtering search results.
    //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
    //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
    //     schemaType: 'multi-enum',

    //     // Optional modes: 'has_all', 'has_any'
    //     // Note: this is relevant only for schema type 'multi-enum'
    //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
    //     searchMode: 'has_all',

    //     // "key" is the option you see in Flex Console.
    //     // "label" is set here for this web app's UI only.
    //     // Note: label is not added through the translation files
    //     // to make filter customizations a bit easier.
    //     options: [
    //       { key: 'towels', label: 'Towels' },
    //       { key: 'bathroom', label: 'Bathroom' },
    //       { key: 'swimming_pool', label: 'Swimming pool' },
    //       { key: 'barbeque', label: 'Barbeque' },
    //     ],
    //   },
    // },
];

export const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,

    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Flex API expects it to be named as 'sort'
    queryParamName: 'sort',

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',

    // Relevance key is used with keywords filter.
    // Keywords filter also sorts results according to relevance.
    relevanceFilter: 'keywords',

    // Keyword filter is sorting the results by relevance.
    // If keyword filter is active, one might want to disable other sorting options
    // by adding 'keyword' to this list.
    conflictingFilters: [],

    options: [
        { key: 'createdAt', label: 'Newest' },
        { key: '-createdAt', label: 'Oldest' },
        { key: '-price', label: 'Lowest price' },
        { key: 'price', label: 'Highest price' },

        // The relevance is only used for keyword search, but the
        // parameter isn't sent to the Marketplace API. The key is purely
        // for handling the internal state of the sorting dropdown.
        {
            key: 'relevance',
            label: 'Relevance',
            longLabel: 'Relevance (Keyword search)',
        },
    ],
};

export const listing = {
    // These should be listing details from public data with schema type: enum
    // SectionDetailsMaybe component shows these on listing page.
    enumFieldDetails: ['platform', 'type', 'media', 'category'],
};
