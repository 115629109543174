import { LinkTabNavHorizontal } from '../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import css from './UserNav.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const UserNav = (props) => {
    const { className, rootClassName, selectedPageName } = props;
    const classes = classNames(rootClassName || css.root, className);

    const tabs = [
        {
            text: <FormattedMessage id="ManageListingsPage.yourListings" />,
            selected: selectedPageName === 'ManageListingsPage',
            linkProps: {
                name: 'ManageListingsPage',
            },
        },
        {
            text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
            selected: selectedPageName === 'ProfileSettingsPage',
            disabled: false,
            linkProps: {
                name: 'ProfileSettingsPage',
            },
        },
        {
            text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
            selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
            disabled: false,
            linkProps: {
                name: 'ContactDetailsPage',
            },
        },
    ];

    return (
        <LinkTabNavHorizontal
            className={classes}
            tabRootClassName={css.tab}
            tabs={tabs}
            skin="dark"
        />
    );
};

UserNav.defaultProps = {
    className: null,
    rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
    className: string,
    rootClassName: string,
    selectedPageName: string.isRequired,
};

export default UserNav;
