import css from './PrivacyPolicy.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PrivacyPolicy = (props) => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);

    // prettier-ignore
    return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: June 26th, 2022</p>

      <p>
        Information according to §5 TMG. <br></br><br></br>
        Moritz Gebhardt <br></br><br></br>Via Vittore Buzzi 7 <br></br>20141 Milan <br></br>+491604240487<br></br> moritz@creator-cosmos.com
      </p>

      <h2>Liability for contents</h2>
      <p>
        As a service provider, we are responsible for our own content on these pages in accordance with 
        § 7 paragraph 1 TMG under the general laws. According to §§ 8 to 10 TMG, we are not obligated to 
        monitor transmitted or stored information or to investigate circumstances that indicate illegal activity. 
        Obligations to remove or block the use of information under the general laws remain unaffected. However, 
        liability in this regard is only possible from the point in time at which a concrete infringement of the 
        law becomes known. If we become aware of any such infringements, we will remove the relevant content 
        immediately.
      </p>

      <h2>Liability for links</h2>
      <p>
        Our offer contains links to external websites of third parties, on whose contents we have no influence. 
        Therefore, we cannot assume any liability for these external contents. The respective provider or 
        operator of the sites is always responsible for the content of the linked sites. The linked pages 
        were checked for possible legal violations at the time of linking. Illegal contents were not 
        recognizable at the time of linking. However, a permanent control of the contents of the 
        linked pages is not reasonable without concrete evidence of a violation of the law. If we become 
        aware of any infringements, we will remove such links immediately.
      </p>

      <h2>Copyright</h2>
      <p>
        The contents and works created by the site operators on these pages are subject to German copyright law. 
        Duplication, processing, distribution, or any form of commercialisation of such material beyond the 
        scope of the copyright law shall require the prior written consent of its respective author or creator. 
        Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the 
        content on this site was not created by the operator, the copyrights of third parties are respected. 
        In particular, third-party content is identified as such. Should you nevertheless become aware of a 
        copyright infringement, please inform us accordingly. If we become aware of any infringements, 
        we will remove such content immediately.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
    rootClassName: string,
    className: string,
};

export default PrivacyPolicy;
